body {
    background-color: #f5f5f5;
    font-family: 'NoirPro', sans-serif;
    font-size: 14px;
}

.hide {
    display: none !important;

}

.header-layout {
    background-color: #fff;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
    height: 72px;
    position: relative;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 12;
    left: 0;
    top: 0;
}

.header-layout .logo-sec {
    width: 240px !important;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    padding-right: 20px;
    padding-left: 15px;
}

.header-layout .logo-sec i {
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
}

.header-layout .logo-sec i:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.header-layout .logo-sec img {
    text-align: center;
    margin: 0 10px;
    width: 100px;
}

.header-layout .header-search {
    padding: 0 30px;
}

.header-layout .header-search .form-group {
    display: flex;
    align-items: center;
    width: 350px;
    background: rgba(250, 250, 250, 0.1);
    padding: 0 15px;
}

.header-layout .header-search .form-group span {
    color: #d9d9d9;
    font-size: 18px;
}

.header-layout .header-search input {
    border-radius: 0px;
    height: 46px;
    padding: 10px 18px 10px 18px;
    border: 0;
    width: 100%;
    background-color: transparent;
}

.header-layout .header-search input::placeholder {
    color: #bbb;
}

.header-layout .header-search input:focus {
    outline: 0;
    box-shadow: none;
}

.header-right {
    margin-left: auto;
    margin-right: 25px;
    display: flex;
    align-items: center;
}

.dropdown-menu:before {
    content: "";
    top: -4px;
    right: 0px;
    left: 0;
    margin: auto;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #fff;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    transform: rotate(45deg);
}

.header-layout .language-btn {
    font-size: 14px;
    color: #545454;
    text-decoration: none;
}

.header-layout .dropdown-toggle::after {
    display: inline-block;
    margin-left: 1.1em;
    vertical-align: .255em;
    content: "";
    box-sizing: border-box;
    height: 8px;
    width: 8px;
    border-style: solid;
    border-color: #000;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
}

.header-layout .language {
    margin-left: auto;
    padding: 15px;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border: 0;
    margin-top: 10px;
}

.header-layout .dropdown-item {
    padding: 0;
    font-size: 14px;
    color: #545454
}

.header-layout .dropdown-item:hover {
    background-color: transparent;
}

.header-layout .profile-btn {
    font-size: 14px;
    color: #545454;
    text-decoration: none;
}

.header-layout .profile-btn img {
    width: 30px;
    border-radius: 50px;
}

.main-layout {
    padding-top: 72px;
    width: calc(100% - 94.5%);
    left: 0;
    background-color: #fff;
    float: left;
    height: 100vh;
    position: fixed;
    background: #fff;
    display: block;
    z-index: 9;
    border-right: 1px solid #e8e8e8;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.main-layout.open {
    padding-top: 72px;
    width: calc(100% - 83%);
    background-color: #fff;
    float: left;
    height: 100vh;
    position: fixed;
    left: 0;
    background: #fff;
    display: block;
    z-index: 9;
    border-right: 1px solid #e8e8e8;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.left-sidebar-layout .sidebar-header {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px 30px 10px 70px;
    height: 72px;

}

.left-sidebar-layout .sidebar-content {}

.left-sidebar-layout .sidebar-content ul {
    list-style: none;
    padding-left: 0;
    margin-top: 20px;
}

.left-sidebar-layout .sidebar-content ul li .sidebar-menu-item {
    color: #545454;
    text-decoration: none;
    line-height: 45px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    padding: 0px 24px;
    display: block;
}

.left-sidebar-layout .sidebar-content ul li .sidebar-menu-item:hover,
.left-sidebar-layout .sidebar-content ul li .sidebar-menu-item.active {
    color: #FF2B7A;
}

.left-sidebar-layout .sidebar-content ul li .sidebar-menu-item i {
    font-size: 20px;
    margin-right: 10px;
}

.left-sidebar-layout .sidebar-content ul li .sidebar-sub-menu-item {
    padding-left: 75px;
}

.menu {
    -webkit-transition: all 0.1s ease-in;
    -moz-transition: all 0.1s ease-in;
    -ms-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
}

.main-container.full {
    padding-top: 100px;
    margin: 0px 20px 80px 20px;
    width: calc(100% - 19.5%);
    float: right;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.main-container {
    padding-top: 100px;
    margin: 0px 20px 80px 20px;
    width: calc(100% - 7.5%);
    float: right;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.card-box {
    margin-bottom: 32px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: 0;
}

.card-header {
    background: #fff;
    border-radius: 25px 25px 0 0 !important;
    padding: 0 1.30rem 15px;
    border-bottom: 0;
}

.card-header .title {
    padding: 25px 0 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.form-control {
    height: 36px;
    padding: 4px 11px;
    color: #545454;
    font-size: 14px;
    line-height: 36px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all 0.3s;
}

.form-control:hover {
    border-color: #ff548d;
}

.form-control:focus,
.has-error .form-control {
    border-color: #ff548d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 43, 122, 0.2);
}

label {
    margin-bottom: 0;
    margin-top: 7px;

}

.btn-primary-fill {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    color: #ffffff !important;
    background-color: #FF2B7A !important;
    border: 1px solid #FF2B7A !important;
    line-height: 34px;
    padding: 0 15px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

.btn-primary-fill:hover {
    color: #ffffff !important;
    background-color: #FF2B7A !important;
    border-color: #FF2B7A !important;
    text-decoration: none;
}

.btn-primary-fill:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 43, 122, 0.19);
    outline: 0
}

.btn-primary-outline {
    position: relative;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-weight: 400;
    text-align: center;
    border: solid 1px #d9d9d9;
    padding: 0 5px;

    font-size: 14px;
    line-height: 34px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    color: #545454;
    background-color: #fff;
    border-color: #d9d9d9;
}

.btn-primary-outline:hover {
    color: #FF2B7A !important;
    background-color: #FFF !important;
    border-color: #FF2B7A !important;
    text-decoration: none;
}

.btn-primary-outline:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 43, 122, 0.19);
    outline: 0
}

.custom-control {
    position: relative;
    display: inline-block;
    min-height: 1.5rem;
    padding-left: 0px;
    width: 40%;
}

.mode {
    position: relative;
    display: inline-block;
    min-height: 1.5rem;
    padding-left: 0px;
    width: 30%;
}

.credit .custom-control {
    position: relative;
    display: inline-block;
    min-height: 1.5rem;
    padding-left: 15px;
    width: 50%;
}

#addCustomer .custom-control {
    width: 44%;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-label {
    margin-bottom: 0;
    padding-left: 10px;
}

.custom-control-label::before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent !important;
    border: 2px solid #FF2B7A !important;
    padding: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    top: 0;
    border-radius: .25rem;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: #FF2B7A !important;
}

.custom-control-input:checked~.custom-control-label::after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: -9px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.custom-control-label::after {
    position: absolute;
    left: -1rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;

}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #ff2b7a75;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(255, 43, 122, 0.19);
    outline: 0
}

input[type="radio"]+.custom-control-label:before {
    border-radius: 25px;
}

.custom-control-input[type="radio"]:checked~.custom-control-label::before {
    background-color: transparent !important;
}

.custom-control-input[type="radio"]:checked~.custom-control-label::after {
    border-width: 0;
    top: 7px;
    left: -10px;
    width: 8px;
    height: 8px;
    background: #ff2b7a;
    border-radius: 50px;
}

.upload-image {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.img-upload input {
    opacity: 0;
    width: 150px;
    height: 30px;
    z-index: 9;
    position: absolute;
    top: 0;
    cursor: pointer;
}

.imgPreview {
    margin-top: 10px;
    border: 1px dashed #a1a1a1;
    width: 144px;
    height: 100px;
    max-height: 100px;
    text-align: center;
    overflow: hidden;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    border-radius: .25rem;
}

.imgPreview input {
    opacity: 0;
    width: 144px;
    height: 100px;
    z-index: 9;
    position: absolute;
    cursor: pointer;
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #000;
    text-shadow: none;
    font-size: 16px;
}

.add-more,
.add-more:hover {
    padding: 5px 10px;
    font-size: 20px;
    color: #FF2B7A;
    text-decoration: none;
}

/*datatable*/
.table {
    border: 0;
}

.table thead th {
    vertical-align: bottom;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    transition: background 0.3s ease;
}

.table-bordered tbody td,
.table-bordered thead th {
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    ;
    padding: 16px 16px;
    color: #535353;

}

/*datatable*/
/*pagination*/


.page-link {
    color: #ff2b7a;
}

.page-item.active .page-link {
    z-index: 3;
    color: #ff2b7a;
    background-color: #ffffff;
    border-color: #ff2b7a;
}

.page-link:hover {
    z-index: 2;
    text-decoration: none;
    color: #ff2b7a;
    background-color: #ffffff;
    border-color: #ff2b7a;
}

.page-link:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 43, 122, 0.19);
}

/*pagination*/

.repeater-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.repeater-block .repeater-value {
    width: 100%;
    margin-right: 10px;
}

#accordion .card-header,
.accordionStatement .card-header {
    background-color: #fafafa;
    border-radius: 4px 4px 0 0 !important;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.accordionStatement .card {
    margin-bottom: 15px;
}

#accordion .card-header a,
#accordion .card-header a:hover,
.accordionStatement .card-header a,
.accordionStatement .card-header a:hover {
    padding: .75rem 1.25rem;
    display: block;
    color: #545454;
    font-weight: 600;
}

#accordion .order-content .order-image,
.accordionStatement .order-content .order-image {
    display: flex;
    flex-wrap: wrap;
}

#accordion .order-content .order-image .img,
.accordionStatement .order-content .order-image .img {
    margin-right: 10px;
    /* height: 65px; */
    overflow: hidden;
    border-radius: 4px;
    width: 150px;
}

#accordion .order-content,
.accordionStatement .order-content {
    padding: 15px 0;
    border-bottom: 1px solid #eaeaea;
}

#accordion .order-content:last-child,
.accordionStatement .order-content:last-child {
    border-bottom: 0
}

.accordionStatement .float-data {
    text-align: right;
}

.generate-statement-btn {
    float: right;
}

.search-label {
    width: 100%;
}

@media (max-width: 768px) {
    #addCustomer .custom-control {
        width: 100%;
    }
}

@media (max-width: 600px) and (min-width: 320px) {
    .header-layout .logo-sec {
        padding: 0 15px;
    }

    .header-layout .logo-sec img {
        width: 70px;
    }

    .header-layout .logo-sec i {
        margin-right: 10px;
    }

    .main-layout,
    .main-layout.open {
        display: none;
    }

    .repeater-block {
        display: block;
    }

    .accordionStatement .float-data {
        text-align: right;
    }

    .generate-statement-btn {
        float: left;
    }
}

.form-group.has-error>.form-control {
    border-color: #ff548d;
}

.btn-primary-fill.btn-disabled,
.btn-primary-outline.btn-disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

input.error {
    border-color: red;
}

#pass.error {
    border-color: red;
}

.input-feedback {
    color: red;
    margin-top: .25rem;
}

.form-check-inline .form-check-input {
    position: absolute;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
    top: 9px;
    left: -13px;
}

.invoice-details-table .table-bordered td,
.invoice-details-table .table-bordered th {

    border-bottom: 0;
}

.invoice-details-table .table-bordered thead th {
    text-align: center;
}

.invoice-details-table .table-bordered thead tr:first-child {
    border-bottom: 1px solid #dee2e6;
}

.invoice-details-table .table-bordered tbody tr:nth-last-child(3),
.invoice-details-table .table-bordered tbody tr:nth-last-child(2) {
    border-top: 1px solid #dee2e6;
    font-weight: bold;
}

.invoice-details-table .table thead th,
.invoice-details-table .table tbody td {
    text-align: left;
}

.invoice-details-table .table tbody tr:last-child {
    font-weight: bold;
}

.invoice-details-table .table tbody tr td:last-child {
    text-align: right;
}

.invoice-details-table a:hover {
    cursor: pointer;
    color: #FF2B7A;
}


.btn.btn-link {
    padding: 0 0.2rem;
}

.nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #ffffff #ffffff #4451a3;
}

.repeater-block {
    display: block;
}

/* switch css */
.switch {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.switch+.lable {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 20px;
    min-height: 18px;
    min-width: 18px;
    font-weight: normal;
    cursor: pointer;
}

.switch+.lable::before {
    cursor: pointer;
    font-family: fontAwesome;
    font-weight: normal;
    font-size: 12px;
    color: #32a3ce;
    content: "";
    background-color: #FAFAFA;
    border: 1px solid #c8c8c8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0;
    display: inline-block;
    text-align: center;
    height: 16px;
    line-height: 14px;
    min-width: 16px;
    margin-right: 1px;
    position: relative;
    top: -1px;
}

.switch:checked+.lable::before {
    display: inline-block;
    content: '';
    background-color: #F5F8FC;
    border-color: #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
}

/* CSS3 on/off switches */
.switch+.lable {
    margin: 0 4px;
    min-height: 24px;
}

.switch+.lable::before {
    font-weight: normal;
    font-size: 11px;
    line-height: 17px;
    height: 20px;
    overflow: hidden;
    border-radius: 12px;
    background-color: #F5F5F5;
    -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid #CCC;
    text-align: left;
    float: left;
    padding: 0;
    width: 52px;
    text-indent: -21px;
    margin-right: 0;
    -webkit-transition: text-indent .3s ease;
    -o-transition: text-indent .3s ease;
    transition: text-indent .3s ease;
    top: auto;
}

.switch.switch-bootstrap+.lable::before {
    font-family: FontAwesome;
    content: "";
    box-shadow: none;
    border-width: 0;
    font-size: 16px;
    background-color: #a9a9a9;
    color: #F2F2F2;
    width: 44px;
    height: 22px;
    line-height: 21px;
    text-indent: 32px;
    -webkit-transition: background 0.1s ease;
    -o-transition: background 0.1s ease;
    transition: background 0.1s ease;
}

.switch.switch-bootstrap+.lable::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    border-radius: 12px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    width: 18px;
    height: 18px;
    text-align: center;
    background-color: #F2F2F2;
    border: 4px solid #F2F2F2;
    -webkit-transition: left 0.2s ease;
    -o-transition: left 0.2s ease;
    transition: left 0.2s ease;
}

.switch.switch-bootstrap:checked+.lable::before {
    content: "";
    text-indent: 6px;
    color: #FFF;
    border-color: #b7d3e5;

}

.switch-primary>.switch.switch-bootstrap:checked+.lable::before {
    background-color: #337ab7;
}

.switch.switch-bootstrap:checked+.lable::after {
    left: 24px;
    background-color: #FFF;
    border: 4px solid #FFF;
    text-shadow: 0 -1px 0 rgba(0, 200, 0, 0.25);
}

.material-table .table-bordered tbody td,
.material-table .table-bordered thead th {
    padding: 16px 5px;
}

.addCustomerModal .custom-control {
    width: 44%;
}

.lineItemAmt {
    margin-top: 7px;
}

@media (max-width: 600px) and (min-width: 320px) {
    .header-layout .logo-sec {
        padding: 0 15px;
    }

    .header-layout .logo-sec img {
        width: 70px;
    }

    .header-layout .logo-sec i {
        margin-right: 10px;
    }

    .main-layout,
    .main-layout.open {
        display: none;
    }

    .repeater-block {
        display: block;
    }
}

.left-sidebar-layout .sidebar-content ul li .sidebar-sub-menu-item {
    padding-left: 75px;
}

#reportDropdown {
    padding-left: 35px;
}
.mar-bottom-5{
  margin-bottom: 5px;
}

.remove-input,
.remove-input:hover {
    padding: 5px 12px;
    line-height: 25px;
    color: #ff0000;
    text-decoration: none;
}
